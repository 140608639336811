/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
	'use strict';
	
	$(document).foundation();

	// slider
	$('.sponsoren .owl-carousel').owlCarousel(
		{
			loop: true,
			autoplay: true,
			smartSpeed: 500,
			autoplayTimeout: 2000,
			autoplayHoverPause: true,
			nav: false,
			dots: false,
			navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"],
			responsive: {
				0: {
					items: 2,
					//stagePadding: 40,
					//margin: 25
				},
				640: {
					items: 4,
					//stagePadding: 40,
					//margin: 25
				},
				1024: {
					items: 6,
					//stagePadding: 40,
					//margin: 70
				}
			}
		}
	);
	$('.cooperation .owl-carousel').owlCarousel(
		{
			loop: true,
			autoplay: true,
			smartSpeed: 500,
			autoplayTimeout: 2000,
			autoplayHoverPause: true,
			nav: false,
			dots: false,
			navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"],
			responsive: {
				0: {
					items: 2,
					//stagePadding: 40,
					margin: 50
				},
				640: {
					items: 3,
					//stagePadding: 50,
					margin: 50
				},
				1024: {
					items: 5,
					//stagePadding: 50,
					margin: 50
				}
			}
		}
	);


	// gomb felirat csere klikkre
	/*$('.korvonal input[type="submit"]').on('click', function(){
		$(this).attr('value', 'Feldolgozás...');
		setTimeout(function(){
			$('.korvonal input[type="submit"]').attr('value', 'Elküld');
		  }, 1000);
	});*/


	// kereses gomb
	$('.search-log .icon').click( function() {
		$('#kereses').toggleClass('open');
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() >= 130) {
			// ha a menu sticky, akkor sitename megjelenik
			if ($('.stick-container-nav .nav').hasClass( 'is-stuck' )) {
				$('#masthead-top .sitename').css('display', 'block');
			};
		} else {
			$('#masthead-top .sitename').css('display', 'none');
		}
	});
	
	// lightslider
	$('#lightSlider').lightSlider({
		gallery: true,
		item: 1,
		auto: true,
		speed: 800,
		pause: 4000,
		adaptiveHeight: true,
		loop: true,
		galleryMargin: 16,
		thumbItem: 3,
		thumbMargin: 40,
		pauseOnHover: true,
		enableDrag: false,
        currentPagerPosition:'left',
        onSliderLoad: function(el) {
            el.lightGallery({
                selector: '#lightSlider .lslide'
			});
		}
	});

	// wrap youtube iframe with div
	$( '.single-post .entry-content iframe' ).wrap( '<div class="embed-youtube">' );

	// scroll up button
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('#scrollup').fadeIn();
		} else {
			$('#scrollup').fadeOut();
		}
	});
	$('#scrollup').click(function () {
		$('html, body').animate({
			scrollTop: 0
		}, 400);
		return false;
	});


	/* function shuffelWord(word) {
		var shuffledWord = '';
		word = word.split('');
		while (word.length > 0) {
		  shuffledWord +=  word.splice(word.length * Math.random() << 0, 1);
		}
		return shuffledWord;
	  }
	  
	  document.querySelector('p').innerHTML = shuffelWord('Batman is the biggest scam.'); */
	  
	  

});